import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"

import { Hero, Office, Competences, Team, Contact } from "page_components/home"

const Home = ({ data, location }) => {
  return (
    <Layout
      seo={{
        title: "Strategic solutions for your business",
      }}
      location={location}
      isHome
    >
      <Hero />
      <Office />
      <Competences data={data?.allWpCompetence?.nodes} />
      <Team />
      <Contact />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpCompetence(
      sort: { order: ASC, fields: date }
      filter: { language: { code: { eq: EN } } }
    ) {
      nodes {
        title
        slug
        id
      }
    }
  }
`

export default Home
